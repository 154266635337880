<template>
  <Add
    v-if="drawStatus === 1"
    :parkInfo="parkInfo"
    :editing="editing"
    :stationInfo="markerDetailInfo"
  ></Add>
  <AddHigh
    v-else-if="drawStatus === 4"
    :parkInfo="parkInfo"
    :editing="editing"
    :stationInfo="markerDetailInfo"
  ></AddHigh>
  <Calibration
    :stationInfo="markerDetailInfo"
    @back="updateCalibration(false)"
    v-else-if="drawStatus === 5"
  ></Calibration>
  <Detail
    v-else
    :stationInfo="markerDetailInfo"
    @onCloseStationDetail="resetHighlightMarker"
    @onDeleteStation="handleDeleteStation"
    @showCalibrate="updateCalibration(true)"
  ></Detail>
</template>
<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import Detail from './Detail.vue';
import Add from './Add.vue';
import AddHigh from './AddHigh.vue';
import Calibration from './Calibration.vue';
import { normalMarkerIcon, selectedMarkerIcon, zIndexMax, markerType } from '@/utils/constantMap';
import { findPoiById } from '@/api/api';
import { getRiskPoiInfo } from '@/api/apiv2';
import NDialog from '@/components/NDialog/index';

let labelsLayer, // 点图层,使用海量标注
  targetMarker, // 当前点击的marker
  size = 40, // 图标大小
  hSize = 56, // 图标高亮大小
  collisionVal = 11, //避让的值
  timestampMarker, // 记录点击marker请求详情的时间戳
  highlightMarker, // 亮显示的点;
  stationBases = []; // 绘制路线的时候显示选中的点

export default {
  name: 'Station',
  props: ['mapZoom'],
  components: { Detail, Add, AddHigh, Calibration },
  watch: {
    mapZoom: {
      handler: function () {
        if (this.parkInfo?.id || !labelsLayer) return;
        labelsLayer.setCollision(this.mapZoom > collisionVal ? false : true);
      },
    },
    parkInfo: {
      handler: function () {
        if (!labelsLayer) return;
        const isCollision = Boolean(this.parkInfo?.id);
        labelsLayer.setCollision(!isCollision);
      },
      deep: true,
    },
  },
  data() {
    return {
      editing: false,
      showCalibrate: false, // 校准点位
      markerDetailInfo: null, // 点击Maker获取点的详情信息
      overlayStyle: null, // 记录当前修改覆盖物样式,防止覆盖物没有加载出来的时候,就点击采集
    };
  },
  computed: {
    ...mapGetters({
      parkInfo: 'getParkInfo',
      drawStatus: 'getDrawStatus',
      isShowLabel: 'getisShowLabelLogisticsMap',
      isShowPOI: 'getisShowPOILogisticsMap',
    }),
  },
  methods: {
    // 渲染POI图层,采用局部更新,移除不存在的,关键字搜索如果页面不存在,需要绘制
    renderLabelsLayer({ stationData, hightItem, isSpecial }) {
      let markerArr = [];
      for (const station of stationData) {
        if (this.parkInfo?.id) {
          const isHigh = hightItem?.id === station.id;
          const marker = this.drawLabelMarker(
            Object.assign(station, { stationName: station.name }),
            isHigh
          );
          markerArr.push(marker);
          isHigh && marker.emit('click', { target: marker }); // 设置搜索的站点自动高亮
        } else {
          const markerOverlay = this.markerSearch(station);
          if (!markerOverlay || (markerOverlay && markerOverlay.index === -1)) {
            const isHigh = hightItem?.id === station.id;
            const marker = this.drawLabelMarker(
              Object.assign(station, { stationName: station.name }),
              isHigh
            );
            markerArr.push(marker);
            isHigh && marker.emit('click', { target: marker }); // 设置搜索的站点自动高亮
          }
        }
      }
      if (labelsLayer) {
        // 如果有搜索条件,除了特殊标注风险点,其它全部重新渲染
        if (this.parkInfo?.id) {
          if (!isSpecial) {
            const labelMarkers = labelsLayer.getAllOverlays();
            for (const marker of labelMarkers) {
              const extData = marker.getExtData();
              if (extData.poiType !== 9) {
                labelsLayer.remove(marker);
              }
            }
          }
        }
        labelsLayer.add(markerArr);
      } else {
        labelsLayer = new AMap.LabelsLayer({
          zIndex: 1000,
          collision: this.mapZoom > collisionVal ? false : true, // 该层内标注是否避让
          allowCollision: false, // 设置 allowCollision：true，可以让标注避让用户的标注
        });
        labelsLayer.add(markerArr);
        if (this.parkInfo.id) {
          labelsLayer.setCollision(false);
        }
        this.$parent.$options.map.add(labelsLayer);
      }
      this.overlayStyle = null;
    },
    drawLabelMarker(markerData, isHigh) {
      const { lng, lat } = markerData.mapData;
      const iconSize = markerData.poiType === -1 ? 32 : size;
      const labelMarker = new AMap.LabelMarker({
        name: markerData.id, // 此属性非绘制文字内容，仅最为标识使用
        position: [lng, lat],
        zIndex: markerData.id * 100,
        icon: {
          type: 'image',
          image: isHigh
            ? selectedMarkerIcon.get(markerData.poiType)
            : normalMarkerIcon.get(markerData.poiType),
          size: isHigh ? [hSize, hSize] : [iconSize, iconSize],
          offset: isHigh ? [0, 4] : [0, 3],
        },
        rank: markerData.id, //避让优先级，rank 值大的标注会避让掉 rank 值低的标注。默认值：1
        extData: markerData, // 设置其它数据
        bubble: false,
      });
      if (this.isShowLabel) {
        labelMarker.setText({
          content: `${markerData.name}`,
          direction: 'top',
          offset: [0, -2],
          style: { backgroundColor: '#ffffff' },
        });
      }
      if (this.overlayStyle) {
        Object.keys(this.overlayStyle).forEach((key) => {
          labelMarker._opts[key] = this.overlayStyle[key];
        });
      }
      labelMarker.on('click', (e) => {
        const { target: Marker } = e;
        let extData = Marker.getExtData();
        targetMarker = Marker;
        if (this.drawStatus === 2) {
          // 绘制路线点击
          bus.emit('onMarkerDetailInfo', extData);
          return;
        }
        if (highlightMarker) {
          const hExtData = highlightMarker.getExtData();
          if (hExtData.id === extData.id) {
            return;
          }
        }
        if (this.drawStatus > 0) return; // 当前点击的marker和之前点击的marker是同一个，则不执行下面的事件
        if (extData.poiType === 3) {
          this.getSpecialMarkerDetail(extData, Marker);
        } else {
          this.getMarkerDetail(extData, Marker);
        }
        Marker.show();
      });
      if (!this.isShowPOI) {
        labelMarker.hide();
      }
      return labelMarker;
    },
    // 查找当前覆盖物中指定的marker
    markerSearch(item) {
      if (!labelsLayer) return;
      const labelMarkers = labelsLayer.getAllOverlays();
      const index = labelMarkers.findIndex((overlay) => {
        const extData = overlay.getExtData();
        if (extData.id === item.id) {
          return overlay;
        }
      });
      return {
        overlay: labelMarkers[index],
        index,
      };
    },
    getMarkerDetail(markerInfo, quoteMarker) {
      timestampMarker = Date.now();
      findPoiById({ id: markerInfo.id, timestamp: timestampMarker }).then((res) => {
        if (res.timestamp !== timestampMarker) return;
        this.markerDetailInfo = {
          ...res,
          mapType: 'POI',
        };
        this.drawHighlightLabelMarker(quoteMarker);
        this.$emit('onClickOverlay', { val: 1 });
      });
    },
    getSpecialMarkerDetail(markerInfo, quoteMarker) {
      getRiskPoiInfo(markerInfo.id).then((res) => {
        this.markerDetailInfo = {
          ...res,
          poiType: 3,
          mapData: markerInfo.mapData,
          mapType: 'POI',
        };
        this.drawHighlightLabelMarker(quoteMarker);
        this.$emit('onClickOverlay', { val: 1 });
      });
    },
    // 点击marker高亮
    drawHighlightLabelMarker(quoteMarker) {
      bus.emit('onResetHighlightOverlay');
      let extData = quoteMarker.getExtData();
      highlightMarker = quoteMarker;
      highlightMarker.setzIndex(zIndexMax);
      highlightMarker.setIcon({
        type: 'image',
        image: selectedMarkerIcon.get(extData.poiType),
        size: [hSize, hSize],
        offset: [0, 4],
      });
      highlightMarker.setRank(zIndexMax);
    },
    resetHighlightMarker() {
      if (!highlightMarker) return;
      const hExtData = highlightMarker.getExtData();
      let image = normalMarkerIcon.get(hExtData.poiType);
      highlightMarker.setIcon({
        type: 'image',
        image: image,
        size: [size, size],
      });
      highlightMarker.setRank(hExtData.id * 100);
      highlightMarker.setzIndex(hExtData.id * 100);
      this.isShowPOI ? highlightMarker.show() : highlightMarker.hide();
      highlightMarker = null;
    },
    drawMarker(markerData, lableIndex) {
      const { lng, lat } = markerData.mapData;
      let iconSize = hSize;
      const markerDic = markerType[markerData.poiType]; // selectedGeen
      let image =
        markerData.poiType === 6 && lableIndex === 0
          ? markerDic.selectedGeen
          : markerDic.selectedRed;
      let y = -(iconSize - 4);
      const marker = new AMap.Marker({
        position: [lng, lat],
        icon: new AMap.Icon({
          size: new AMap.Size(iconSize, iconSize),
          image: image,
          imageSize: [iconSize, iconSize],
        }),
        offset: new AMap.Pixel(-(iconSize / 2), y), //相对于基点的偏移位置
        extData: { ...markerData, lableIndex }, // 设置其它数据
        zIndex: markerData.id * 100,
        bubble: false,
      });
      if (this.isShowLabel) {
        let content = `<div class='marker-label marker-label-hight'><span class='start'>起</span>${markerData.name}</div>`;
        if (lableIndex === 1) {
          content = `<div class='marker-label marker-label-hight'><span class='end'>终</span>${markerData.name}</div>`;
        }
        marker.setLabel({
          direction: 'top',
          offset: new AMap.Pixel(-1, 0),
          content: content,
        });
      }
      marker.on('click', (e) => {
        setTimeout(() => {
          const { target: Marker } = e;
          let extData = Marker.getExtData();
          bus.emit('onMarkerDetailInfo', extData);
        }, 0);
      });
      this.$parent.$options.map.add(marker);
      return marker;
    },
    // 修改当前覆盖物的属性
    onChangeOverlayOptions(payload) {
      this.overlayStyle = payload.common;
      if (!labelsLayer) return;
      let labelMarkers = labelsLayer.getAllOverlays() || [];
      labelMarkers.forEach((labelMarker) => {
        Object.keys(this.overlayStyle).forEach((key) => {
          labelMarker._originOpts[key] = this.overlayStyle[key];
        });
      });
      labelsLayer.setzIndex(payload.zIndex);
    },
    // 删除站点
    handleDeleteStation() {
      if (this.markerDetailInfo?.id) {
        const markerOverlay = this.markerSearch(this.markerDetailInfo);
        labelsLayer.remove(markerOverlay.overlay);
        this.markerDetailInfo = null;
        this.$store.commit('updateIsShowdetail', 0);
      }
    },
    // 新增站点
    handleAddNewMarker(markerData) {
      const { poiType, poiPictures, pictureList } = markerData;
      if (
        (poiType === 6 && (poiPictures === null || poiPictures.length === 0)) ||
        (poiType === 3 && pictureList.length === 0)
      ) {
        NDialog.confirm({
          title: '提示',
          message:
            '该点位还未上传' + (poiType === 6 ? '停车图文指引' : '风险描述') + '，是否立即上传？',
          okText: '立即上传',
          cancelText: '暂不上传',
        }).then((result) => {
          if (result === 'confirm') {
            if (poiType === 6) {
              this.$router.push({
                name: 'parkingGuide',
                query: markerData,
              });
            } else {
              this.$router.push({
                name: 'imageUpload',
                query: { id: markerData.id },
              });
            }
          }
        });
      }

      // 判断是否是编辑.如果是编辑需要删除之前的信息,然后新增
      if (markerData.isEditing) {
        const markerOverlay = this.markerSearch(markerData);
        labelsLayer.remove(markerOverlay.overlay);

        this.$store.commit('updateLockGestureMap', false);
      }
      const labelsMarker = this.drawLabelMarker(markerData);
      labelsLayer.add(labelsMarker);
      setTimeout(() => {
        labelsMarker.emit('click', { target: labelsMarker });
      });
    },
    // 监听隐藏点,比如高亮选中线的时候,需要把点隐藏
    handleIshowMarkerOverlay(isShow) {
      if (!labelsLayer) return;
      if (isShow) {
        labelsLayer.show();
      } else {
        labelsLayer.hide();
      }
    },
    // POI的显示和隐藏
    handlePOIChange() {
      if (!labelsLayer) return;
      let labelMarkers = labelsLayer.getAllOverlays() || [];
      for (const labelMarker of labelMarkers) {
        const markerData = labelMarker.getExtData();
        // 点击POI高亮的点不受影响，也不出来
        if (highlightMarker) {
          const hMarkerData = highlightMarker.getExtData();
          if (hMarkerData.id === markerData.id) {
            continue;
          }
        }
        if (markerData.poiType !== -1) {
          if (this.isShowPOI) {
            labelMarker.show();
          } else {
            labelMarker.hide();
          }
        }
      }
    },
    // 名称的显示和隐藏
    handleLabelChange() {
      if (!labelsLayer) return;
      let labelMarkers = labelsLayer.getAllOverlays() || [];
      labelMarkers.forEach((labelMarker) => {
        const markerData = labelMarker.getExtData();
        if (markerData.poiType !== -1) {
          if (this.isShowLabel) {
            labelMarker.setText({
              content: markerData.name,
              direction: 'top',
              offset: [0, -2],
              style: { backgroundColor: '#ffffff' },
            });
          } else {
            labelMarker.setText({ content: '' });
          }
        }
      });
      if (stationBases.length > 0) {
        stationBases.forEach((marker) => {
          const markerData = marker.getExtData();
          let content = ``;
          if (this.isShowLabel) {
            content = `<div class='marker-label marker-label-hight'><span class='start'>起</span>${markerData.name}</div>`;
            if (markerData.lableIndex === 1) {
              content = `<div class='marker-label marker-label-hight'><span class='end'>终</span>${markerData.name}</div>`;
            }
          }
          marker.setLabel({
            direction: 'top',
            offset: new AMap.Pixel(-1, 0),
            content: content,
          });
        });
      }
    },
    // 关键字搜索查找站点
    handleSelectLocation(payload) {
      if (payload.locationType !== 1) return;
      this.$parent.$options.map.setCenter([payload.mapData.lng, payload.mapData.lat], true);
      const markerOverlay = this.markerSearch(payload);
      if (~markerOverlay.index) {
        markerOverlay.overlay.emit('click', { target: markerOverlay.overlay });
      } else {
        payload.poiType = payload.selfType;
        this.renderLabelsLayer({ stationData: [payload], hightItem: payload });
      }
    },
    // 绘制路线的时候,显示选中的点
    handleChangeStationBase(stationIds) {
      let stationIdsArr = stationIds.split(',');
      const labelMarkers = labelsLayer.getAllOverlays();
      for (const labelMarker of labelMarkers) {
        const extData = labelMarker.getExtData();
        if ([3, 9].includes(extData.poiType)) continue;
        const idx = stationIdsArr.findIndex((id) => extData.id === Number(id));
        if (~idx) {
          labelMarker.hide();
          const isIndexDrawed = stationBases.findIndex((s) => {
            const sExtData = s.getExtData();
            return extData.id === sExtData.id;
          });
          // 已经绘制过的不需要再次绘制
          if (!~isIndexDrawed) {
            stationBases.push(this.drawMarker(extData, idx));
          }
        } else {
          labelMarker.show();
          stationBases = stationBases.filter((marker) => {
            const markerInfo = marker.getExtData();
            if (markerInfo.id === extData.id) {
              marker.remove();
              return false;
            }
            return true;
          });
        }
      }
    },
    // 控制风险点的显示和隐藏,case:路线编辑和高亮的时候
    handleIshowSpecialMarkerOverlay(isShow) {
      const labelMarkers = labelsLayer.getAllOverlays();
      for (const marker of labelMarkers) {
        const extData = marker.getExtData();
        if (extData.poiType === 3) {
          isShow ? marker.show() : marker.hide();
        }
      }
    },
    /**
     * drawStatus 绘制的类型,点线面
     */
    handleDrawOverlay(drawStatus) {
      if (drawStatus === 2) {
        // 开始采集路线需要把风险点隐藏
        this.handleIshowSpecialMarkerOverlay(false);
      }
    },
    handleHighMarkerVisible(status) {
      if (status) {
        targetMarker?.show();
      } else {
        targetMarker?.hide();
      }
    },

    changeParkingGuide(id) {
      timestampMarker = Date.now();
      findPoiById({ id, timestamp: timestampMarker }).then((res) => {
        if (res.timestamp !== timestampMarker) return;
        this.markerDetailInfo = {
          ...res,
          mapType: 'POI',
        };
      });
    },
    // 处理bus的监听事件
    monitorBus() {
      bus.on('onNameClick', this.handleLabelChange);
      bus.on('onPOIShowOrHide', this.handlePOIChange);
      bus.on('onResetHighlightOverlay', this.resetHighlightMarker); // 点击地图高亮消失
      bus.on('onCalibrationSuccess', this.handleCalibrationSuccess); // 校准位置
      // 修改地图覆盖物的属性,比如采集的时候,要bubble要设置为true,并且手势修改
      bus.on('onChangeOverlayOptions', this.onChangeOverlayOptions);
      bus.on('onDrawOverlay', this.handleDrawOverlay);
      bus.on('onIshowMarkerOverlay', this.handleIshowMarkerOverlay);
      bus.on('onSelectLocation', this.handleSelectLocation);
      bus.on('onAddNewMarker', this.handleAddNewMarker);
      // 绘制路线的时候,显示选中的点
      bus.on('onChangeStationBase', this.handleChangeStationBase);
      // 风险点的显示和隐藏
      bus.on('onIshowSpecialMarkerOverlay', this.handleIshowSpecialMarkerOverlay);
      bus.on('onCancelEditStation', this.cancelEditStation);
      bus.on('onEditStation', this.onEditStation);
      bus.on('onHighMarkerVisible', this.handleHighMarkerVisible);
      bus.on('changeParkingGuide', this.changeParkingGuide);
    },

    selectAddType(type) {
      this.isShowPopup = true;
      this.addType = type;
    },
    updateCalibration(status) {
      if (status) {
        this.$store.commit('updateDrawStatus', 5);
      } else {
        this.$store.commit('updateDrawStatus', 0);
      }
    },
    // complete的作用，如果是校准完成的时候，关闭校准弹窗
    handleCalibrationSuccess({ x, y }) {
      this.markerDetailInfo.highPrecision = 1;
      highlightMarker.setPosition([x, y]);
      window.AMap.lcs.map.setCenter([x, y], false);
      this.$store.commit('updateDrawStatus', 0);
      highlightMarker.emit('click', { target: highlightMarker });
    },

    onEditStation(stationInfo) {
      this.$store.commit('updateLockGestureMap', true);
      if (stationInfo.highPrecision && stationInfo.originalCoordinates) {
        window.AMap.lcs.map.setCenter(stationInfo.originalCoordinates.split(','), false);
      } else {
        window.AMap.lcs.map.setCenter([stationInfo.lng, stationInfo.lat], false);
      }
      this.editing = true;
      this.resetHighlightMarker();
      targetMarker.hide();
      this.$store.commit('updateDrawStatus', stationInfo.highPrecision ? 4 : 1);
      if (stationInfo.highPrecision) {
        this.$store.commit('updateisShowHighPrecisionLogisticsMap', true);
      }
    },

    // 取消编辑
    cancelEditStation() {
      this.$store.commit('updateLockGestureMap', false);
      this.editing = false;
      setTimeout(() => {
        targetMarker.show();
        targetMarker.emit('click', { target: targetMarker });
      });
    },
  },
  mounted() {
    this.monitorBus();
  },
  destroyed() {
    bus.all.clear();
  },
};
</script>
<style scoped lang="scss"></style>
