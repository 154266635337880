<!-- 筛选条件列表 -->
<template>
  <div>
    <van-action-sheet :value="isShow" title="数据显示" @cancel="onCancel" @click-overlay="onCancel">
      <div class="tool-action-sheet-content">
        <div class="switch-cell">
          <span class="switch-key">高精图层</span>
          <van-switch
            v-model="$store.state.LogisticsMap.isShowHighPrecisionLogisticsMap"
            active-color="rgba(70, 95, 253, 1)"
            inactive-color="rgba(225, 230, 237, 1)"
          />
        </div>
        <div v-show="isShowDrawSwitch" class="switch-cell">
          <span class="switch-key">点位</span>
          <van-switch
            :value="$store.state.LogisticsMap.isShowPOILogisticsMap"
            active-color="rgba(70, 95, 253, 1)"
            inactive-color="rgba(225, 230, 237, 1)"
            @change="stationChange"
          />
        </div>
        <div class="switch-cell">
          <span class="switch-key">点位名称</span>
          <van-switch
            :value="$store.state.LogisticsMap.isShowLabelLogisticsMap"
            active-color="rgba(70, 95, 253, 1)"
            inactive-color="rgba(225, 230, 237, 1)"
            @change="nameChange"
          />
        </div>
        <div v-show="isShowDrawSwitch" class="switch-cell">
          <span class="switch-key">线路</span>
          <van-switch
            :value="$store.state.LogisticsMap.isShowRouteLogisticsMap"
            active-color="rgba(70, 95, 253, 1)"
            inactive-color="rgba(225, 230, 237, 1)"
            @change="routeChange"
          />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import bus from '@/utils/bus';
import { mapGetters } from 'vuex';
export default {
  components: {},
  props: ['isShow'],
  data() {
    return {
      value: '',
      selectVehicle: {}, // 当前选择的vin
      filterList: [],
    };
  },
  computed: {
    ...mapGetters({
      drawStatus: 'getDrawStatus',
    }),
    isShowDrawSwitch() {
      return this.drawStatus !== 2;
    },
  },
  watch: {},
  methods: {
    onCancel() {
      this.$emit('update:isShow');
    },
    stationChange() {
      const val = !this.$store.state.LogisticsMap.isShowPOILogisticsMap;
      this.$store.commit('updateisShowPOILogisticsMap', val);
      bus.emit('onPOIShowOrHide');
      if (!val) {
        // 如果点位关闭，点位名称也关闭
        this.$store.commit('updateisShowLabelLogisticsMap', false);
        bus.emit('onNameClick');
      }
    },
    nameChange() {
      const val = !this.$store.state.LogisticsMap.isShowLabelLogisticsMap;
      this.$store.commit('updateisShowLabelLogisticsMap', val);
      bus.emit('onNameClick');
      if (val && this.isShowDrawSwitch) {
        // 如果名称打开，点位也打开
        this.$store.commit('updateisShowPOILogisticsMap', true);
        bus.emit('onPOIShowOrHide');
      }
    },
    routeChange() {
      this.$store.commit(
        'updateisShowRouteLogisticsMap',
        !this.$store.state.LogisticsMap.isShowRouteLogisticsMap
      );
      bus.emit('onRouteShowOrHide');
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
::v-deep .van-popup {
  pointer-events: all;
}
::v-deep .van-overlay {
  pointer-events: all;
}
.tool-action-sheet-content {
  padding: 22px 20px 32px;
  .switch-cell {
    display: flex;
    justify-content: space-between;
    & + .switch-cell {
      margin-top: 22px;
    }
  }
  ::v-deep .van-switch {
    position: relative;
    width: 44px;
    height: 24px;
    border: unset;
    .van-switch__node {
      width: 18px;
      height: 18px;
      top: 3px;
      right: unset;
      left: 3px;
      box-shadow: unset;
    }
  }
  ::v-deep .van-switch--on {
    .van-switch__node {
      width: 18px;
      height: 18px;
      right: 31px;
      left: unset;
    }
  }
}
</style>
