<template>
  <div class="">
    <BackBar @click="backClick" title="校准位置"></BackBar>
    <Bottom isShowTool>
      <template v-slot:footer>
        <div class="detail">
          <div class="content">
            <div class="warning">选择车辆，以其所在位置作为校准位置</div>
            <div class="content-item vehicle-type">
              <span>车辆</span>
              <span @click="showPicker = true">
                <span v-if="vehicleInfo?.vin" class="selected">{{ vehicleInfo?.vinId }}</span>
                <span v-else>请选择车辆</span>
                <img src="@/assets/imgs/arrRight.png" alt="" />
              </span>
            </div>
            <div class="content-btnGroup">
              <div class="content-submit" @click="handleOk" :class="{ disabled: !isComplete }">
                确定校准
              </div>
            </div>
          </div>
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              v-if="showPicker"
              title="车辆"
              show-toolbar
              :default-index="activeIndex"
              value-key="vinId"
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onPickerConfirm"
            />
          </van-popup>
        </div>
      </template>
    </Bottom>
  </div>
</template>
<script>
import Bottom from '@/views/LogisticsMap/components/common/Bottom.vue';
import BackBar from '@/views/LogisticsMap/components/common/BackBar.vue';
import { getVehiclesForAOI, updatePoiLatAndLng, checkPOICanBeCalibrate } from '@/api/apiv2';
import NDialog from '@/components/NDialog';
import bus from '@/utils/bus';

export default {
  components: {
    Bottom,
    BackBar,
  },
  props: {
    stationInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showPicker: false,
      activeIndex: null,
      vehicleInfo: { vin: null, vinId: null },
      columns: [],
      isLoading: false,
    };
  },
  computed: {
    isComplete() {
      return this.vehicleInfo?.vin && !this.isLoading;
    },
  },
  methods: {
    backClick() {
      this.$emit('back');
    },

    getVehicleList() {
      getVehiclesForAOI({
        parkCode: this.stationInfo.parkCode,
      }).then((res) => {
        this.columns = res;
      });
    },
    onPickerConfirm(item, index) {
      this.vehicleInfo = item;
      this.activeIndex = index;
      this.showPicker = false;
    },
    async handleOk() {
      if (!this.isComplete) {
        this.showPicker = false;
        return;
      }
      this.isLoading = true;
      // POI校准前的校验接口
      const checkRes = await checkPOICanBeCalibrate({
        id: this.stationInfo.id,
        vin: this.vehicleInfo.vin,
      });
      this.isLoading = false;
      if (checkRes.code === '10000') {
        if (checkRes.data.length === 0) {
          // 没有关联路线直接更新坐标
          this.updatePoiLatAndLngServe(checkRes.data);
        } else {
          // 关联路线给予提示
          const updateRes = await NDialog.confirm({
            title: '提示',
            message: `若确定校准，该停靠点关联的${checkRes.data.length}条自动驾驶线路将批量重规划，请谨慎操作`,
            okText: '确定校准',
            cancelText: '取消',
          });
          if (updateRes === 'confirm') {
            this.updatePoiLatAndLngServe(checkRes.data);
          }
        }
      } else if (['40020', '40021'].includes(checkRes.code)) {
        await NDialog.confirm({
          title: '车辆坐标获取失败',
          message: '若车辆不在采图过程中，请检查车辆地图与运营区域是否匹配',
          okText: '我知道了',
          isHideCancel: true,
        });
      } else if (checkRes.code === '40017') {
        await NDialog.confirm({
          title: '提示',
          message: checkRes.msg,
          okText: '我知道了',
          isHideCancel: true,
        });
      } else {
        this.$toast(checkRes.msg);
      }
    },
    // isUpdateRoute是否需要更新路线
    updatePoiLatAndLngServe(routeIdList) {
      updatePoiLatAndLng({
        id: this.stationInfo.id,
        vin: this.vehicleInfo.vin,
      }).then((res) => {
        if (routeIdList.length === 0) {
          this.$toast('停靠点校准成功');
          bus.emit('onCalibrationSuccess', { ...res });
        } else {
          const { parkCode, id } = this.stationInfo;
          this.$router.push(`/buildRouteByStaion?parkCode=${parkCode}&stationId=${id}&entryType=1`);
        }
      });
    },
  },
  mounted() {
    this.getVehicleList();
  },
  beforeDestroy() {},
};
</script>
<style scoped lang="scss">
.detail {
  width: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  padding: 18px 18px 32px;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.05);
}

.point-mark {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -100%);
  z-index: 9;

  span {
    font-size: 12px;
    color: #222222;
    font-weight: bold;
    display: block;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 4px 12px;
    margin-bottom: 4px;
    width: max-content;
  }

  img {
    width: 28px;
    height: 40px;
  }
}

.content {
  .warning {
    background: #fef2ea;
    border-radius: 0.1rem;
    font-size: 0.26rem;
    color: #fe8000;
    padding: 0.17rem 0.2rem;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 0.24rem;
    font-weight: bold;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(234, 238, 238, 1);
    &.poi-parkName {
      padding-top: 8px;
    }

    > span {
      &:nth-child(1) {
        width: 80px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(151, 152, 171, 1);
      }
      &:nth-child(2) {
        position: relative;
        flex: 1;
        > span {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(151, 152, 171, 1);
          &.selected {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
          }
        }
        input {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0px;
          color: rgba(0, 0, 0, 1);
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(151, 152, 171, 1);
          }
        }
        img {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }
  }

  &-btnGroup {
    margin-top: 12px;
    display: flex;
    align-items: center;

    .content-submit {
      flex-grow: 1;
      box-sizing: border-box;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border-radius: 23px;
      background: rgba(70, 95, 253, 1);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      &.disabled {
        opacity: 0.2;
      }
      &:not(.disabled) {
        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}
</style>
