<template>
  <div>
    <div v-if="authBtn.includes('采集/绘制标注')" class="collect-entrance">
      <div v-if="hasDataPermission" class="stationAndRoute">
        <div @click="clickPopup(1)" class="station-collect">
          <img src="@/assets/LogisticsMap/station-collect.png" /> 采集点位
        </div>
        <div @click="startDraw(2)" class="route-collect">
          <img src="@/assets/LogisticsMap/route-collect.png" /> 规划线路
        </div>
      </div>
      <div v-else @click="startDraw(3)" class="park-collect">
        <img src="@/assets/LogisticsMap/park-collect.png" /> 创建网点
      </div>
    </div>
    <collect-popup :isShow.sync="isShowPopup" @select="selectAddType"></collect-popup>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import CollectPopup from './CollectPopup.vue';
import NDialog from '@/components/NDialog';

export default {
  name: 'CollectEntrance',
  props: ['parkInfo'],
  components: { CollectPopup },
  inject: ['provideLogisticsMap'],
  data() {
    return {
      isShowPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      authBtn: 'getAuthBtn',
      hasDataPermission: 'getHasDataPermission',
    }),
  },
  methods: {
    clickPopup() {
      if (!this.parkInfo?.id) {
        this.$toast('请先在顶部网点栏中选择网点');
        return;
      }
      if (this.provideLogisticsMap.userType === 1) {
        // CP 账号不显示高精
        this.selectAddType(1);
      } else {
        this.isShowPopup = true;
      }
    },
    selectAddType(type) {
      this.startDraw(type);
      this.isShowPopup = false;
      this.$store.commit('updateIsShowdetail', 0);
      if (type === 4) {
        this.$store.commit('updateisShowHighPrecisionLogisticsMap', true);
      }
      this.openSwitch();
      bus.emit('onRouteShowOrHide');
    },
    startDraw(val) {
      if (val !== 3 && !this.parkInfo?.id) {
        this.$toast('请先在顶部网点栏中选择网点');
        return;
      }
      if (val === 2) {
        this.openSwitch();
      }
      this.$store.commit('updateDrawStatus', val);
      if ([3].includes(val)) {
        bus.emit('onChangeOverlayOptions', { common: { bubble: true }, zIndex: 1 });
      }
      bus.emit('onResetHighlightOverlay');
      bus.emit('onDrawOverlay', val);
    },
    async showPopupWhenAddNewPark() {
      const result = await NDialog.confirm({
        message: '是否马上开始采集点位',
        okText: '开始采集',
        cancelText: '暂不开始',
      });
      if (result === 'confirm') {
        this.clickPopup();
      }
    },
    // 绘制线路的时候，如果点线隐藏，需要打开
    openSwitch() {
      if (!this.$store.state.LogisticsMap.isShowPOILogisticsMap) {
        this.$store.commit('updateisShowPOILogisticsMap', true);
        bus.emit('onPOIShowOrHide');
      }
      if (!this.$store.state.LogisticsMap.isShowRouteLogisticsMap) {
        this.$store.commit('updateisShowRouteLogisticsMap', true);
      }
    },
  },
  mounted() {
    bus.on('onAddNewPark', (value) => {
      if (value && value.isEditing === false) {
        this.showPopupWhenAddNewPark();
      }
    });
  },
  destroyed() {
    bus.off('onAddNewPark');
  },
};
</script>
<style scoped lang="scss">
.collect-entrance {
  width: 100%;
  // height: 96px;
  display: flex;
  justify-content: center;
  background: white;
  padding: 18px 18px 32px;
  box-sizing: border-box;
  border-radius: 16px 16px 0px 0px;
  margin-top: 20px;
  .park-collect,
  .station-collect,
  .route-collect {
    font-size: 14px;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 46px;
    background: rgba(70, 95, 253, 1);
    border: 4px solid rgba(70, 95, 253, 1);
    img {
      width: 21px;
      height: 21px;
      margin-right: 4px;
    }
  }
  .stationAndRoute {
    width: 100%;
    display: grid;
    grid-template-rows: 46px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
  }
  .park-collect {
    width: 100%;
    height: 46px;
  }
}
</style>
