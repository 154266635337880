<template>
  <div class="logisticsMap">
    <div>
      <div class="mapContainer" ref="mapContainer"></div>
      <div class="mapHeader" style="top: var(--appBarHeight)">
        <div v-if="drawStatus === 0" class="backBtn" @click="backToPrev">
          <img src="@/assets/imgs/back-icon-map.png" alt="" />
        </div>
        <!-- 不是绘制，且不是校准poi-->
        <template v-if="drawStatus === 0">
          <div class="searchBar" @click="searchClick">
            <span v-if="parkInfo && parkInfo.parkCode" class="filled">{{ parkInfo.parkName }}</span>
            <span v-else>请选择网点</span>
            <div class="input-suffix">
              <img
                v-if="parkInfo && parkInfo.parkCode"
                @click.stop="clearClick"
                class="clear"
                src="../../assets/LogisticsMap/clear.png"
                alt=""
              />

              <img
                class="arrows"
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/arrow-down.png"
                alt=""
              />
              <div
                v-if="hasDataPermission && !isShowDetail && authBtn.includes('采集/绘制标注')"
                class="collect-park"
              >
                <div class="gap"></div>
                <div class="collect-park-btn" @click.stop="startDrawPark">
                  <img src="@/assets/LogisticsMap/park-collect-blue.png" />
                  <div>创建网点</div>
                </div>
              </div>
            </div>
          </div>
          <div class="search-other" @click="locationSearch">
            <img src="../../assets/LogisticsMap/search-icon.png" alt="" />
          </div>
        </template>
        <!-- <div class="zoom-test">{{ mapZoom }}</div> -->
      </div>
    </div>
    <div v-show="isShowBottom">
      <Bottom :isShowTool="authBtn.includes('采集/绘制标注')">
        <template v-slot:footer>
          <div
            v-if="authBtn.length > 0"
            class="first-guid"
            :class="{ 'first-guid-bttom-20': !authBtn.includes('采集/绘制标注') }"
            @click="showFirstGuid = true"
          >
            <img src="../../assets/LogisticsMap/first-guid.png" />
          </div>
          <CollectEntrance :parkInfo="parkInfo"></CollectEntrance>
        </template>
      </Bottom>
    </div>
    <HighPrecision></HighPrecision>
    <Station ref="stationRef" :mapZoom="mapZoom" @onClickOverlay="handleClickOverlay"></Station>
    <Routes
      ref="routeRef"
      :mapZoom="mapZoom"
      :onlyShowPoi="onlyShowPoi"
      @onClickOverlay="handleClickOverlay"
    ></Routes>
    <Park ref="parkRef" :mapZoom="mapZoom" @onClickOverlay="handleClickOverlay"></Park>
    <van-popup
      v-model="showFirstGuid"
      position="bottom"
      style="height: 70%; background: transparent"
      :close-on-click-overlay="false"
    >
      <FirstGuide @finish="onFirstGuideFinish" />
    </van-popup>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import * as turf from '@turf/turf';
import { mapGetters } from 'vuex';
import { debounce, setLogisticsMapLocalStorage } from '@/utils/index';
import bus from '@/utils/bus';
import HighPrecision from './components/common/HighPrecision.vue';
import Station from '@/views/LogisticsMap/components/Station/index.vue';
import Routes from '@/views/LogisticsMap/components/Routes/index.vue';
import Park from '@/views/LogisticsMap/components/Park/index.vue';
import CollectEntrance from './components/common/CollectEntrance.vue';
import Bottom from './components/common/Bottom.vue';
import { requestLocationPermission, getLocation, splashFinish } from '@/utils/index';
import { getListForAll, getRoleProductInfo } from '@/api/api';
import { getAllSpecialRiskPoi, estimateHaveDataAuth, getAuthParkList } from '@/api/apiv2';
import { zIndexMax } from '@/utils/constantMap';
import centerGif from '../../assets/LogisticsMap/marker/center.gif';
import FirstGuide from './components/common/FirstGuide.vue';

const userLocation =
  'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/userLocation.png';
const defaultZoom = 16;
const CONFIG = {
  zoom: defaultZoom,
  zooms: [2, 25],
  center: [116.405994, 39.913828],
  // center: [120.64680258915865, 31.107296048821734],
  animateEnable: false, // 关闭平移动画
  mapStyle: 'amap://styles/f4b151947a9bbff39c125ad140e47219',

  // dragEnable: false,      // 控制地图拖拽
};
const onlyShowPOIVal = 9;
let bounds, // 地图可视区边界
  debouncedAction,
  centerMarkerTimer, //地图中心展示定时器
  centerMarker, //地图定位图标
  isClickOverlay = false, //   解决点击覆盖物造成的偏移问题
  userMarker;
export default {
  components: {
    CollectEntrance,
    HighPrecision,
    Station,
    Routes,
    Park,
    Bottom,
    FirstGuide,
  },
  data() {
    return {
      rightBtnHeight: `1rem`,
      mapZoom: CONFIG.zoom,
      localStorage: { location: [] }, // 需要记录的数据
      timestamp: null, //记录数据请求的时间
      onlyShowPoi: false,
      showFirstGuid: false,
      provideLogisticsMap: {
        userType: 0, //0为普通用户 1为cp用户
      },
    };
  },
  provide() {
    return {
      provideLogisticsMap: this.provideLogisticsMap, //0为普通用户 1为cp用户
    };
  },
  watch: {
    mapZoom: {
      handler: function () {
        if (this.parkInfo || this.drawStatus > 0) return;
        const isShow = this.mapZoom >= onlyShowPOIVal;
        this.$refs.parkRef.handleHideParkOverlay(isShow);
      },
    },
    lockGestureMap: {
      handler: function (value) {
        this.$options.map.setStatus({ dragEnable: !value, zoomEnable: !value });
      },
    },
  },
  computed: {
    ...mapGetters({
      parkInfo: 'getParkInfo',
      drawStatus: 'getDrawStatus',
      isShowDetail: 'getIsShowdetail',
      lockGestureMap: 'getLockGestureMap',
      authBtn: 'getAuthBtn',
      hasDataPermission: 'getHasDataPermission',
    }),
    // 是否展示地图上的工具
    isShowBottom() {
      return this.drawStatus === 0 && this.isShowDetail === 0;
    },
  },
  methods: {
    // 读取原生存储的数据:地图位置,AOI网格存储;特殊记录,如果这个页面有任何调二级的地方,都需要原生屏蔽一下,不要主动触发这个方法
    async getLogisticsMapLocalStorage(payload) {
      if (this.$route.name !== 'logisticsMap') return;
      // 地图移动至上次记录的位置,若无记录位置则位移至用户所在位置
      if (payload.fence) {
        const res = await getAuthParkList({});
        const idx = res.findIndex((item) => item.parkCode === payload.fence.parkCode);
        if (~idx) {
          this.handleSearchSelect(payload.fence);
          return;
        }
      }
      if (payload?.location?.length > 0) {
        this.localStorage = payload;
        this.$options.map.setCenter(this.localStorage.location);
      } else {
        window.sendLocationInfo = this.sendLocationInfo;
        getLocation(); // 调用webview
      }
    },
    sendLocationInfo(lat, lng) {
      if (lat && lng) {
        const location = [lng, lat];
        this.localStorage.location = location;
        setLogisticsMapLocalStorage(JSON.stringify(this.localStorage));
        this.drawUserMarker('complete', { position: this.localStorage.location });
        this.$options.map.setCenter(this.localStorage.location);
      } else {
        this.drawUserMarker('error');
      }
    },
    initMap() {
      this.$loadingCircle.start();
      AMapLoader.load({
        key: 'e618efe9bc3dbbbd1ddfa9d847f49d74',
        version: '2.0',
        plugins: [
          'AMap.Geolocation',
          'AMap.MapboxVectorTileLayer',
          'AMap.PolylineEditor',
          'AMap.PolygonEditor',
          'AMap.Geocoder',
          'AMap.Driving',
          'AMap.Riding',
        ],
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.$options.map = new AMap.Map(this.$refs.mapContainer, CONFIG);
          this.mapEvent();
        })
        .catch((error) => console.log(error));
    },

    mapEvent() {
      let _this = this;
      this.$options.map.on('complete', () => {
        bounds = this.$options.map.getBounds();
        this.$loadingCircle.end();
        this.getListForAllServer();
        // this.getAllSpecialRiskPoiServe();
      });
      this.$options.map.on('click', (event) => {
        // 获取当前点击地图的坐标
        bus.emit('onMapClick', event);
        if (this.drawStatus > 0) {
          return;
        }
        this.resetHight();
      });
      this.$options.map.on('zoomchange', (event) => {
        this.mapZoom = event.target.getZoom();
        this.onlyShowPoi = this.mapZoom < onlyShowPOIVal;
      });
      this.$options.map.on('moveend', (e) => {
        if (isClickOverlay) return;
        this.onlyShowPoi = this.mapZoom < onlyShowPOIVal;
        bounds = this.$options.map.getBounds();
        const center = this.$options.map.getCenter();
        this.localStorage.location = [center.getLng(), center.getLat()];
        setLogisticsMapLocalStorage(JSON.stringify(this.localStorage));
        if (this.clickOverlay || this.parkInfo?.id || this.drawStatus > 0 || this.mapZoom < 7)
          return;
        debouncedAction(); // 没有搜索条件局部加载 或者没有因为点击覆盖物高亮造成的偏移
      });
    },
    drawUserMarker(state, result) {
      if (!userMarker) {
        userMarker = new AMap.Marker({
          position: result.position,
          visible: false,
          offset: [-14, -14],
          zIndex: 2147483647,
          icon: new AMap.Icon({
            size: [28, 28],
            image: userLocation,
            imageSize: [28, 28],
          }),
        });
        this.$options.map.add(userMarker);
      }
      if (state === 'complete') {
        userMarker.setPosition(result.position);
        userMarker.show();
      } else {
        userMarker.hide();
        requestLocationPermission();
      }
    },
    backToPrev() {
      window.location.href = 'neolix://more';
    },
    searchClick() {
      this.$router.push({
        name: 'logisticsMapSearchPark',
      });
    },
    clearClick() {
      this.$store.commit('updateParkInfo', {});
      this.getListForAllServer();
      this.localStorage.fence = null;
      setLogisticsMapLocalStorage(JSON.stringify(this.localStorage));
    },
    // 开始绘制网点
    startDrawPark() {
      let val = 3;
      this.$store.commit('updateDrawStatus', val);
      bus.emit('onChangeOverlayOptions', { common: { bubble: true }, zIndex: 1 });
      bus.emit('onResetHighlightOverlay');
      bus.emit('onDrawOverlay', val);
    },
    getListForAllServer() {
      this.timestamp = Date.now();
      let parameter = {
        timestamp: this.timestamp,
        onlyShowPoi: this.onlyShowPoi,
      };
      if (this.parkInfo?.id) {
        parameter.aoiId = this.parkInfo.id;
      } else {
        const diagonallyCoordinate = this.formatVisibleCoordinate();
        parameter.diagonallyCoordinate = diagonallyCoordinate;
      }
      getListForAll(parameter).then((res) => {
        if (res.length > 0) {
          const firstItem = res[0];
          if (firstItem.timestamp !== this.timestamp) return;
        }
        let stationData = [],
          routeData = [],
          parkData = [];
        for (const item of res) {
          if (item.mapType === 'POI' && item.mapData) {
            item.poiType = item.selfType;
            stationData.push(item);
          } else if (item.mapType === 'ROUTE' && item.mapData) {
            routeData.push(item);
          } else if (['AOI', 'REGION'].includes(item.mapType) && item.mapData) {
            parkData.push(item);
          }
        }
        this.$refs.stationRef.renderLabelsLayer({ stationData });
        this.$refs.parkRef.renderPolygonOverlayGroup({ parkData });
        this.$refs.routeRef.renderPolylineOverlayGroup({ routeData });
      });
    },
    async getAllSpecialRiskPoiServe() {
      const res = await getAllSpecialRiskPoi({});
      if (res && res.length > 0) {
        const stationData = res.map((item) => {
          item.poiType = 9;
          return item;
        });
        this.$refs.stationRef.renderLabelsLayer({ stationData, isSpecial: true });
      }
    },
    // 计算可视区的范围
    formatVisibleCoordinate() {
      if (!bounds) return;
      const southWest = bounds.getSouthWest(); // 西南角经纬度
      const northEast = bounds.getNorthEast(); // 东北角经纬度值
      const point1 = turf.point([southWest.getLng(), southWest.getLat()]);
      const point2 = turf.point([northEast.getLng(), northEast.getLat()]);
      let extendedDistance = 5;
      const bearing1 = turf.rhumbBearing(point2, point1);
      const prolongPoint1 = turf.destination(point1, extendedDistance, bearing1, {
        units: 'kilometers',
      });
      const bearing2 = turf.rhumbBearing(point1, point2);
      const prolongPoint2 = turf.destination(point2, extendedDistance, bearing2, {
        units: 'kilometers',
      });
      const prolongPoint1Coordinate = prolongPoint1.geometry.coordinates;
      const prolongPoint2Coordinate = prolongPoint2.geometry.coordinates;
      return [prolongPoint1Coordinate, prolongPoint2Coordinate];
    },
    handleClickOverlay({ val }) {
      this.$store.commit('updateIsShowdetail', val);
      isClickOverlay = true;
      setTimeout(() => {
        isClickOverlay = false;
      }, 2000);
    },
    // 取消地图覆盖物的高亮
    resetHight() {
      bus.emit('onResetHighlightOverlay');
      this.$store.commit('updateIsShowdetail', 0);
    },
    // 获取当前用户的权限
    getRoleProductInfoServe() {
      getRoleProductInfo({}).then((res) => {
        if (res && res.length > 0) {
          const menuJson = res.find((v) => v.productApplicationName === '准时达').menuJson;
          const authBtn = this.recursionBtn(menuJson, 'menuName'); //按钮权限
          this.$store.commit('updateAuthBtn', authBtn);
          this.$store.commit('updateUserType', res[0].userType);
        }
      });
    },
    //递归出按钮所有有权限的项
    recursionBtn(data, key, arr = []) {
      data.map((v) => {
        if (v.children) {
          this.recursionBtn(v.children, key, arr);
        } else {
          if (v.check) arr.push(v[key]);
        }
      });
      return arr;
    },
    locationSearch() {
      this.$router.push({
        name: 'locationSearch',
        query: { aoiId: this.parkInfo?.id },
      });
    },

    setMapCenter(option) {
      const centerSize = 60;
      centerMarker && this.$options.map.remove(centerMarker);
      centerMarker = new AMap.Marker({
        position: option.position,
        icon: new AMap.Icon({
          size: new AMap.Size(centerSize, centerSize),
          image: `${centerGif}?timp=${Date.now()}`,
          imageSize: new AMap.Size(centerSize, centerSize),
        }),
        offset: new AMap.Pixel(-(centerSize / 2), -(centerSize - 10)), //相对于基点的偏移位置
        zIndex: zIndexMax,
      });
      if (option.name) {
        centerMarker.hide();
        centerMarker.show();
        centerMarker.setLabel({
          direction: 'top',
          offset: [0, 10],
          content: `<div class='marker-label'>${option.name}</div>`, //设置文本标注内容
        });
      }
      this.$options.map.add(centerMarker);
      this.$options.map.setCenter(option.position, true);
      centerMarkerTimer && clearTimeout(centerMarkerTimer);
      centerMarkerTimer = setTimeout(() => {
        centerMarker.remove();
      }, 6000);
    },
    // 选中位置
    handleSelectLocation(payload) {
      if (payload.locationType === 0 && payload.location) {
        this.setMapCenter({ position: payload.location, name: payload.name });
      }
    },
    handleSearchSelect(payload) {
      this.$store.commit('updateIsShowdetail', 0);
      this.$store.commit('updateParkInfo', payload);
      this.$options.map.setCenter(payload.fenceCenterPoint, true);
      this.localStorage.fence = payload;
      setLogisticsMapLocalStorage(JSON.stringify(this.localStorage));
      if (payload.view !== 'CollectPark') {
        // 如果是通过采集Park进来的,不要获取所有的覆盖物信息,否则Park高亮会消失
        this.getListForAllServer();
      }
    },
    handleGetLocation() {
      getLocation();
      window.sendLocationInfo = this.sendLocationInfo;
    },
    // 处理bus的监听事件
    monitorBus() {
      // 定位当前位置
      bus.on('onGetLocation', this.handleGetLocation);

      // 关键字搜索
      bus.on('onSelectLocation', this.handleSelectLocation);
      // 网格搜索
      bus.on('onSelectPark', this.handleSearchSelect);
    },
    onFirstGuideFinish() {
      this.showFirstGuid = false;
      splashFinish();
    },
    async estimateHaveDataAuthServer() {
      const res = await estimateHaveDataAuth({});
      this.$store.commit('updateHasDataPermission', !res.noAuth);
    },
  },
  created() {
    if (this.$route.query.firstStartApp === 'true') {
      this.showFirstGuid = true;
    }
    this.provideLogisticsMap.userType = this.$route.query.userType === '1' ? 1 : 0;
  },
  mounted() {
    window.getLogisticsMapLocalStorage = this.getLogisticsMapLocalStorage;
    debouncedAction = debounce(this.getListForAllServer, 1000, true);
    this.estimateHaveDataAuthServer();
    this.getRoleProductInfoServe();
    this.initMap();
    this.monitorBus();
  },
  activated() {
    if (this.$options.map) {
      this.$options.map.resize();
    }
  },
  destroyed() {
    bus.all.clear();
  },
};
</script>
<style lang="scss">
a.amap-logo {
  display: none !important;
}
a.amap-copyright {
  display: none !important;
}
</style>
<style scoped lang="scss">
.first-guid {
  position: absolute;
  left: 7px;
  bottom: 116px;
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    object-fit: cover;
  }
  &.first-guid-bttom-20 {
    bottom: 20px;
  }
}
:deep(.van-picker) {
  .van-picker__cancel {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }

  .van-picker__title {
    font-size: 16px;
    font-weight: bold;
  }

  .van-picker__confirm {
    font-size: 14px;
    font-weight: 400;
    color: rgba(67, 110, 255, 1);
  }

  .van-picker-column__item {
    font-size: 16px;
    font-weight: 400;

    &.van-picker-column__item--selected {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

:deep(.amap-marker-label) {
  padding: 0;
  line-height: initial;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.toLocalEnv {
  position: absolute;
  z-index: 999;
  right: 12px;
  top: 200px;
  font-size: 20px;
  width: 50px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: white;
  color: cyan;
  border-radius: 18px;
}

.logisticsMap {
  position: relative;
  font-size: initial;

  .centerMarker {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -100%);
    z-index: 9;

    span {
      font-size: 12px;
      color: #222222;
      font-weight: bold;
      display: block;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      padding: 4px 12px;
      margin-bottom: 4px;
      width: max-content;
    }

    img {
      width: 28px;
      height: 40px;
    }
  }

  .pickupContent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 6rem;
    background: white;
    padding: 0.32rem 0.28rem;
    box-sizing: border-box;
    z-index: 10;
    box-shadow: 0px -0.04rem 0.12rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.32rem 0.32rem 0px 0px;

    .warning {
      background: #fef2ea;
      border-radius: 0.1rem;
      font-size: 0.26rem;
      color: #fe8000;
      padding: 0.17rem 0.2rem;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 0.24rem;
      font-weight: bold;
    }

    .parkName {
      display: flex;
      padding: 0.24rem 0;
      border-bottom: 1px solid rgba(234, 238, 238, 1);

      span {
        display: block;
        font-size: 0.32rem;

        &:nth-child(1) {
          min-width: 1.52rem;
          font-size: 0.32rem;
          color: #9798ab;
        }

        &:nth-child(2) {
          min-width: 1.52rem;
          color: #000000;
          font-weight: bold;
        }
      }
    }

    .stationName {
      padding: 0.24rem 0;
      border-bottom: 1px solid rgba(234, 238, 238, 1);
      display: flex;

      span {
        display: block;
        min-width: 1.52rem;
        font-size: 0.32rem;
        color: #9798ab;
      }

      .nameInner {
        flex-grow: 1;
        display: flex;
        align-items: center;
        input {
          flex-grow: 1;
          font-size: 0.32rem;
          color: #000000;
          font-weight: bold;

          &::placeholder {
            color: #bfc0cb;
            font-weight: normal;
          }
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 12px;
        }
      }
    }

    .stationType {
      padding: 0.24rem 0;
      border-bottom: 1px solid rgba(234, 238, 238, 1);
      display: flex;
      align-items: center;

      > span {
        min-width: 1.52rem;
        font-size: 0.32rem;
        color: #9798ab;
      }

      .typeInner {
        flex-grow: 1;
        display: flex;
        align-items: center;

        span {
          flex-grow: 1;
          font-weight: bold;
          color: rgba(0, 0, 0, 1);
        }

        img {
          width: 14px;
          height: 14px;
          margin-right: 12px;
        }
      }
    }

    .pickupButton {
      height: 0.92rem;
      line-height: 0.94rem;
      font-size: 0.28rem;
      color: #ffffff;
      font-weight: bold;
      border-radius: 0.46rem;
      background: #436eff;
      border-radius: 0.46rem;
      margin: 0.4rem 0;
      text-align: center;

      &.disabled {
        background: #cbd5fb;
      }
    }

    .pickupButton-phase2 {
      height: 0.92rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0.4rem 0;

      > div {
        width: 3.15rem;
        height: 0.92rem;
        opacity: 1;
        border-radius: 0.48rem;
        text-align: center;
        font-size: 0.28rem;
        font-weight: bold;
        line-height: 0.92rem;
        &:nth-child(1) {
          color: #436eff;
          font-weight: bold;
          background: rgba(255, 255, 255, 1);
          border: 0.04rem solid rgba(67, 110, 255, 1);
        }

        &:nth-child(2) {
          background: #436eff;
          color: white;
          line-height: 0.94rem;
          &.disabled {
            background: #cbd5fb;
          }
        }
      }
    }
  }

  .parkingGuide {
    font-size: initial;
    background: white;
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
    border-radius: 16px 16px 0px 0px;
    padding: 0 24px;
    box-sizing: border-box;

    // &.hasLabel {
    //   height: 108px;
    // }

    .poiType {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 80px;
      height: 24px;
      background: url(../../assets/imgs/label_bg.png) center no-repeat;
      background-size: contain;
      text-align: center;
      line-height: 24px;
      color: rgba(67, 110, 255, 1);
      font-weight: bold;
    }

    .parkingContent {
      // &.hasLabel {
      //   margin-top: 18px;
      // }

      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footer-btn {
        // width: 300px;
        // height: 46px;
        // opacity: 1;
        // flex-grow: 1;

        // margin-right: 32px;
        padding: 12px 19px;
        border-radius: 46px;
        background: rgba(67, 110, 255, 1);

        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        // line-height: 44px;
        color: rgba(255, 255, 255, 1);
        text-align: center;

        &.gotoContacts {
          padding: 10px 17px;
          background: white;
          color: rgba(70, 95, 253, 1);
          border: 2px solid rgba(67, 110, 255, 1);
        }
      }

      .footer-delete {
        > div {
          &:nth-child(1) {
            width: 26px;
            height: 26px;

            img {
              width: 26px;
              height: 26px;
            }
          }

          &:nth-child(2) {
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            // line-height: 33.6px;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
  }

  .mapContainer {
    width: 100vw;
    height: 100vh;

    ::v-deep .amap-copyright {
      display: none !important;
    }
  }

  .mapHeader {
    width: 100vw;
    position: absolute;
    // top: 0.1rem;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    // justify-content: space-around;
    padding: 0 10px;

    .backBtn {
      background: #ffffff;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      padding: 12px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 32px;
      }
    }

    .searchBar {
      background: #ffffff;
      flex: 1;
      height: 40px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      color: #bfc0cb;
      box-sizing: border-box;
      padding: 10px 12px;
      .input-suffix {
        display: flex;
        align-items: center;
      }
      span {
        &.filled {
          font-size: 15px;
          color: #000000;
          font-weight: 500;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; /* 这里是超出几行省略 */
          overflow: hidden;
        }
      }
      .clear {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
      .arrows {
        width: 16px;
        height: 16px;
      }
      .collect-park {
        display: flex;
        align-items: center;
        margin-left: 8px;
        min-width: 38px;
        .gap {
          width: 1px;
          height: 24px;
          background: rgba(235, 235, 235, 1);
          margin-right: 5px;
        }
        .collect-park-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          font-weight: 600;
          color: rgba(70, 95, 253, 1);
          img {
            width: 21px;
          }
        }
      }
    }

    .search-other {
      background: #ffffff;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      padding: 12px;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
// 文本标签
:deep(.amap-marker-label) {
  border: none;
  background: transparent;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  width: max-content;
  white-space: normal;
  .marker-label {
    background: #ffffff;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    padding: 2px 3px;
    max-width: 228px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;
    letter-spacing: 0.4px;
    padding-top: 3px;
  }
  .marker-label-hight {
    background: #ffffff;
    padding: 0;
    padding-right: 4px;
    max-width: max-content;
    height: 23px;
    span {
      display: inline-block;
      height: 23px;
      text-align: center;
      line-height: 23px;
      font-size: 11px;
      margin-right: 8px;
      padding: 0 4px;
    }
    .start {
      font-size: 14px;
      background: rgba(38, 197, 117, 1);
      color: white;
    }
    .end {
      font-size: 14px;
      background: rgba(250, 75, 62, 1);
      color: white;
    }
  }
  .tujing-marker-label {
    background: #ffffff;
  }
  .ad-station-unset-width {
    max-width: unset;
  }
}
.marker-text-hight {
  background: #ffffff;
  padding: 0;
  padding-right: 4px;
  max-width: max-content;
  span {
    height: 20px;
    background: rgba(250, 75, 62, 1);
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-right: 8px;
    padding: 0 7px;
    display: flex;
    align-items: center;
  }
}
.zoom-test {
  position: absolute;
  width: 50px;
  top: 60px;
  right: 10px;
}
// 信息窗体
:deep(.amap-info) {
  .info-window-motorway {
    position: relative;
    background: #ffffff;
    border: 1px solid #31c6d4;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: rgba(10, 30, 94, 1);
    padding: 4px 8px;
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: #31c6d4;
      bottom: -2px;
      left: 50%;
      transform: rotate(45deg);
      z-index: -1;
    }
    .delete_motorway {
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url('../../assets/LogisticsMap/delete-blue.png');
      background-size: 12px;
      margin-left: 6px;
      cursor: pointer;
    }
    .motorway-name {
      display: inline-block;
      min-width: 76px;
    }
  }
}
</style>
